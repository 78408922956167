import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';

import { PropsWithTestId } from '../../../../../../../types';

import styles from './Preview.module.scss';

export interface PreviewProps extends HTMLAttributes<HTMLElement> {
  src: string;
}

export default function Preview(props: PropsWithTestId<PreviewProps>) {
  const { src, className, testId, ...restProps } = props;
  const paragraphClassName = cn(styles.wrapper, className);

  return (
    <div {...restProps} data-testid={testId} className={paragraphClassName}>
      <ImageTag className={styles.holder} src={src} alt="" />
      <div className={styles.playWrapper}>
        <span className={cn('material-icons', styles.icon)}>play_circle</span>
      </div>
    </div>
  );
}

Preview.defaultProps = {
  testId: 'hub-module-preview',
};
