import {
  HubModule,
  HubModuleCTA,
  UploadedImage,
} from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import { VideoModuleSources } from '../../../VideoPlayer/constants';

export enum VideoModulePositions {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum VideoModuleStyles {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum VideoModuleWidth {
  STANDARD = 'STANDARD',
  PAGE = 'PAGE',
}

export enum VideoModuleDisplayOption {
  OVERLAY = 'OVERLAY',
  PLAY_IN_PLACE = 'PLAY_IN_PLACE',
  NEW_WINDOW = 'NEW_WINDOW',
}

export interface VideoModuleType extends HubModule {
  '@type': HubModuleTypes.Video;
  title: string;
  text: string;
  link: HubModuleCTA;
  posterImage?: UploadedImage;
  videoId: string;
  videoPosition: VideoModulePositions;
  videoWidth: VideoModuleWidth;
  videoSource: VideoModuleSources;
  videoDisplayOption: VideoModuleDisplayOption;
  videoUrl: string;
  style: VideoModuleStyles;
}
